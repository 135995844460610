<template>
    <v-card>
        <v-container fluid>
            <v-row class="px-4">
                <v-card-title class="text-h5">
                    {{ $t('cargoPlanner.title') }}
                </v-card-title>
            </v-row>
            <v-row class="px-4">
                <v-col cols="12" md="3">
                    <v-row>
                        <v-col cols="12" md="6" >
                            <label>{{ $t('cargoPlanner.height') }}</label>
                            <v-text-field v-model.number="boxSize.width" type="number" outlined required validate-on-blur
                                dense single-line hide-details="auto"
                                :rules="[onlyNaturalNumbers, onlyInteger]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" >
                            <label>{{ $t('cargoPlanner.width') }}</label>
                            <v-text-field v-model.number="boxSize.height" type="number" outlined required validate-on-blur
                                dense single-line hide-details="auto"
                                :rules="[onlyNaturalNumbers, onlyInteger]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="12" >
                            <v-btn
                            color="primary"
                            outlined
                            @click="setSize()"
                        >
                        {{ $t('common.set') }}
                        </v-btn>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="4" >
                            <label>{{ $t('cargoPlanner.height') }}</label>
                            <v-text-field v-model.number="newCargo.height" type="number" outlined required validate-on-blur
                                dense single-line hide-details="auto"
                                :rules="[onlyNaturalNumbers, onlyInteger]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" >
                            <label>{{ $t('cargoPlanner.width') }}</label>
                            <v-text-field v-model.number="newCargo.width" type="number" outlined required validate-on-blur
                                dense single-line hide-details="auto"
                                :rules="[onlyNaturalNumbers, onlyInteger]"></v-text-field>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="5" >
                            <v-btn
                            color="primary"
                            outlined
                            @click="addCargo()"
                        >
                        {{ $t('cargoPlanner.add') }}
                        </v-btn>
                        </v-col>
                        <v-col cols="12" md="6" >
                            <v-btn
                            color="secondary"
                            outlined
                            @click="clearAll()"
                        >
                        {{ $t('cargoPlanner.clear') }}
                        </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item, index) in cargos" :key="index"
        @mouseover="handleMouseOver(index)"
        @mouseleave="handleMouseLeave(index)">
                        <v-col cols="12" md="4" >
                            <label>{{ $t('cargoPlanner.height') }}</label>
                            <v-text-field readonly v-model.number="item.oheight" type="number" outlined required validate-on-blur
                                dense single-line hide-details="auto"
                                :rules="[onlyNaturalNumbers, onlyInteger]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="4" >
                            <label>{{ $t('cargoPlanner.width') }}</label>
                            <v-text-field readonly v-model.number="item.owidth" type="number" outlined required validate-on-blur
                                dense single-line hide-details="auto"
                                :rules="[onlyNaturalNumbers, onlyInteger]"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="1" class="container d-flex">
                            <v-btn icon class="justify-content-center align-self-center" x-small @click="close(index)" tile>
                                <v-icon class="pa-0" small dense >$close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col cols="12" md="2" class="container d-flex">
                            <div class="justify-content-center align-self-center"  :style="{ height: '30px', width: '30px', backgroundColor: item.originalColor}"></div>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12" md="9">
                    <v-stage  ref="stage" :config="{
        width: stageSize.width + 400,
        height: stageSize.height,
        shadowBlur: stageSize.shadowBlur
                    }">
                        <v-layer ref="layer" :config="{
                        shadowBlur: 1
                        }">
                        <v-rect
                             :config="{
                            x: 1,
                        y: 1,
                        width: stageSize.width -2,
                        height: stageSize.height -2,
                        fill: 'white',
                        shadowBlur: 2
                        }"
                        />
                            <v-rect v-for="(item, index) in cargos" :key="index"
                            @dblclick="handleClick(item, index)"
                            @dragstart="handleDragStart"
                            @dragend="handleDragend"
                            @dragMove="handleDragMove"
                             :config="{
                            id: index,
                            x: item.x,
                        y: item.y,
                        draggable: true,
                        width: item.width,
                         height: item.height,
                        fill: item.color,
                        shadowBlur: item.shadow,
                        shadowColor: '#000000'
                        }"
                    />
      </v-layer>
    </v-stage>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
import VueKonva from 'vue-konva'
import Vue from 'vue'
import store from '@/store'

Vue.use(VueKonva)
export default {
  data () {
    return {
      scale: 1,
      previousPosition: null,
      boxSize: {
        width: null,
        height: null
      },
      stageSize: {
        width: 0,
        height: 0,
        shadowBlur: 1
      },
      newCargo: {
        width: 50,
        height: 50
      },
      cargos: [],
      onlyNaturalNumbers: (v) => v ? v > 0 ? true : this.$t('validation.onlyNaturalNumbers') : true,
      onlyInteger: (v) => v ? Number.isInteger(v) ? true : this.$t('validation.onlyInteger') : true
    }
  },
  methods: {
    handleMouseOver (index) {
      var item = this.cargos.at(index)
      item.shadow = 10
    },
    handleMouseLeave (index) {
      var item = this.cargos.at(index)
      item.shadow = 0
    },
    handleDragStart (e) {
      // Store the initial position of the dragged object
      this.previousPosition = { x: e.target.x(), y: e.target.y() }
      console.log('drag start', this.previousPosition)
    },
    close (index) {
      this.cargos.splice(index, 1)
    },
    setSize () {
      this.scale = this.boxSize.width / 1000
      this.stageSize.width = 1000
      this.stageSize.height = this.boxSize.height / this.scale
      this.cargos = []
    },
    clearAll () {
      this.cargos = []
    },
    async addCargo () {
      var color = this.getRandomColor()
      var item = { shadow: 0, id: this.cargos.length, owidth: this.newCargo.width, oheight: this.newCargo.height, width: this.newCargo.height / this.scale, height: this.newCargo.width / this.scale, x: 0, y: 0, color: color, originalColor: color }
      var newItem = this.findXY(item, this.cargos.length)
      if (newItem === null) {
        await store.dispatch('setErrorNotification', this.$t('cargoPlanner.addError'))
        return
      }

      console.log('item', newItem)
      this.cargos.push(newItem)
    },
    getRandomColor () {
      var res = Math.floor(Math.random() * 16777215).toString(16)
      return '#' + res
    },
    handleDragend (e) {
      var item = this.cargos.at(e.target.id())
      item.x = e.target.x()
      item.y = e.target.y()
    },
    findXY (item, i) {
      var stage = this.stageSize
      var hasCollision = true
      while (hasCollision) {
        hasCollision = false
        this.cargos.forEach(function (group, index) {
          if (index !== i) {
            var r1 = group
            var r2 = item
            if (!(r2.x >= r1.x + r1.width ||
            r2.x + r2.width <= r1.x ||
            r2.y >= r1.y + r1.height ||
            r2.y + r2.height <= r1.y
            )) {
              hasCollision = true
              item.x = item.x + 1
              if (item.x + item.width > stage.width) {
                item.x = 0
                item.y = item.y + 1
              }
              if (item.y + item.height > stage.height) {
                return null
              }
            }
          }
        })
      }
      if (item.x + item.width > stage.width || item.y + item.height > stage.height) {
        return null
      }
      return item
    },
    handleDragMove (e) {
      var lastPos = this.previousPosition
      var targetRect = e.target.getClientRect()
      var item = this.cargos.at(e.target.id())

      var stage = this.stageSize
      this.cargos.forEach(function (group, index) {
        // do not check intersection with itself
        if (index === e.target.id()) {
          return false
        }
        var r1 = group
        var r2 = targetRect
        if (!(r2.x >= r1.x + r1.width ||
          r2.x + r2.width <= r1.x ||
          r2.y >= r1.y + r1.height ||
          r2.y + r2.height <= r1.y
        )) {
          var box = targetRect
          const otherBox = group
          if (Math.abs(box.x - otherBox.x) > Math.abs(box.y - otherBox.y)) {
            if (box.x < otherBox.x) {
              e.target.x(otherBox.x - box.width)
              console.log('targetRect.x', e.target.x())
              if (targetRect.x < 0) {
                e.target.x(otherBox.x + otherBox.width)
              }
            } else {
              e.target.x(otherBox.x + otherBox.width)
              if (targetRect.x + item.width > stage.width) {
                e.target.x(otherBox.x - box.width)
              }
            }
          } else {
            if (box.y < otherBox.y) {
              e.target.y(otherBox.y - box.height)
              if (e.target.y() < 0) {
                e.target.y(otherBox.y + otherBox.height)
              }
            } else {
              e.target.y(otherBox.y + otherBox.height)
              if (e.target.y() + item.height > stage.height) {
                e.target.y(otherBox.y - box.height)
              }
            }
          }
        }
      })

      if (e.target.x() < 0) {
        e.target.x(0)
      }
      if (e.target.x() + item.width > this.stageSize.width) {
        e.target.x(this.stageSize.width - item.width)
      }
      if (e.target.y() < 0) {
        e.target.y(0)
      }
      if (e.target.y() + item.height > this.stageSize.height) {
        e.target.y(this.stageSize.height - item.height)
      }

      targetRect = e.target.getClientRect()
      this.cargos.forEach(function (group, index) {
        // do not check intersection with itself
        if (index === e.target.id()) {
          return false
        }
        var r1 = group
        var r2 = targetRect
        if (!(r2.x >= r1.x + r1.width ||
          r2.x + r2.width <= r1.x ||
          r2.y >= r1.y + r1.height ||
          r2.y + r2.height <= r1.y
        )) {
          e.target.position(lastPos)
        }
      })

      lastPos.x = e.target.x()
      lastPos.y = e.target.y()
    },
    handleClick (item, i) {
      var w = item.width
      var h = item.height
      console.log(item)
      var ow = item.owidth
      var oh = item.oheight
      item.height = w
      item.width = h
      var newItem = this.findXY(item, i)
      if (newItem != null) {
        item = newItem
        item.oheight = ow
        item.owidth = oh
      } else {
        item.x = 0
        item.y = 0
        var newItem2 = this.findXY(item, i)
        if (newItem2 != null) {
          item = newItem2
          item.oheight = ow
          item.owidth = oh
        } else {
          item.height = h
          item.width = w
        }
      }
    }
  }
}
</script>
